import { ROUTES_NAME } from "../../config/constants";
import { ACCESS_TOKEN } from "../../constants";
import {
  ADVANCE_SEARCH_ERROR,
  ADVANCE_SEARCH_REQUEST,
  ADVANCE_SEARCH_SUCCESS,
} from "./ActionType";

export const getAdvanceSearchStoreData = (payload) => {
  const { name, country, chargesStatus, startDate, endDate, page,pageLength,plan} = payload;
  return async (dispatch) => {
    dispatch(advanceSearchRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.table}`;

    await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        page: page,
        pageLength: pageLength,
        name: name,
        country: country,
        chargesStatus: chargesStatus,
        startDate: startDate,
        endDate: endDate,
        plan:plan
      }),
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(advanceSearchSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(advanceSearchError());
      });
  };
};

function advanceSearchRequest(receivedData) {
  return {
    type: ADVANCE_SEARCH_REQUEST,
    payload: receivedData,
  };
}

function advanceSearchSuccess(receivedData) {
  return {
    type: ADVANCE_SEARCH_SUCCESS,
    payload: receivedData,
  };
}

function advanceSearchError(receivedData) {
  return {
    type: ADVANCE_SEARCH_ERROR,
    payload: receivedData,
  };
}
