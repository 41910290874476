import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import { shopID } from "../../../utils";
import {
  FIND_BY_ID_ERROR,
  FIND_BY_ID_REQUEST,
  FIND_BY_ID_SUCCESS,
} from "./ActionType";

export const getStoreDetails = (shopId) => {
  return (dispatch) => {
    dispatch(findByIDRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.find_by_id(shopId)}`;

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(findByIDSuccess(res));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(findByIDError());
      });
  };
};

function findByIDRequest(data) {
  return {
    type: FIND_BY_ID_REQUEST,
    payload: data,
  };
}
function findByIDSuccess(data) {
  return {
    type: FIND_BY_ID_SUCCESS,
    payload: data,
  };
}
function findByIDError(data) {
  return {
    type: FIND_BY_ID_ERROR,
    payload: data,
  };
}
