import { ROUTES_NAME } from '../../../config/constants';
import { ACCESS_TOKEN } from '../../../constants';
import {RESYNC_ORDERS_ERROR, RESYNC_ORDERS_REQUEST, RESYNC_ORDERS_SUCCESS} from './ActionType'

export const getResyncOrdersCount = (shopId) => {
    return (dispatch) => {
        dispatch(reSynceCountRequest());
        const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.resync(shopId)}`;

    fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
            'Content-Type': 'application/json',
            'Origin': `${process.env.REACT_APP_URL}`
        },
      })
        .then((res) => res.json())
        .then((newtworth) => {
          dispatch(reSynceCountSuccess(newtworth));
        })
        .catch((data) => {
          console.log(data, "error");
          dispatch(reSynceCountError());
        });
    }
}


function reSynceCountRequest(netWorthData) {
    return {
        type: RESYNC_ORDERS_REQUEST,
        payload: netWorthData,
    };
}

function reSynceCountSuccess(netWorthData) {
    return {
        type: RESYNC_ORDERS_SUCCESS,
        payload: netWorthData,
    };
}

function reSynceCountError(netWorthData) {
    return {
        type: RESYNC_ORDERS_ERROR,
        payload: netWorthData,
    };
}