import { ROUTES_NAME } from "../../../config/constants";
import {
  ADD_CARRIERS_ERROR,
  ADD_CARRIERS_REQUEST,
  ADD_CARRIERS_SUCCESS,
  ALL_CARRIERS_ERROR,
  ALL_CARRIERS_REQUEST,
  ALL_CARRIERS_SUCCESS,
  DELETE_CARRIERS_ERROR,
  DELETE_CARRIERS_REQUEST,
  DELETE_CARRIERS_SUCCESS,
  LIST_CARRIERS_GEO_ERROR,
  LIST_CARRIERS_GEO_REQUEST,
  LIST_CARRIERS_GEO_SUCCESS,
} from "./ActionType";
import { shopID } from "../../../utils";
import { ACCESS_TOKEN } from "../../../constants";

export const getAllCarrierTable = (shopId) => {
  return (dispatch) => {
    dispatch(allCarrierRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.storeAllCarrier(shopId)}`;

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(allCarrierSuccess(res));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(allCarrierError());
      });
  };
};

function allCarrierRequest(data) {
  return {
    type: ALL_CARRIERS_REQUEST,
    payload: data,
  };
}
function allCarrierSuccess(data) {
  return {
    type: ALL_CARRIERS_SUCCESS,
    payload: data,
  };
}
function allCarrierError(data) {
  return {
    type: ALL_CARRIERS_ERROR,
    payload: data,
  };
}

export const getListCarrierByGeo = (payload) => {
  const { carrier, shopId } = payload;
  return (dispatch) => {
    dispatch(allListCarrierRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.storeAllGeoCarrier(shopId)}`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        paypalGeo: carrier,
      }),
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(allListCarrierSuccess(res));
      })
      .catch((data) => {
        dispatch(allListCarrierError());
      });
  };
};

function allListCarrierRequest(data) {
  return {
    type: LIST_CARRIERS_GEO_REQUEST,
    payload: data,
  };
}
function allListCarrierSuccess(data) {
  return {
    type: LIST_CARRIERS_GEO_SUCCESS,
    payload: data,
  };
}
function allListCarrierError(data) {
  return {
    type: LIST_CARRIERS_GEO_ERROR,
    payload: data,
  };
}

export const deleteCarrier = (payload) => {
  const { id, shopId } = payload;
  return (dispatch) => {
    dispatch(deleteCarrierRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.deleteCarrier(shopId)}`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        carrierOverrideId: id,
      }),
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(deleteCarrierSuccess(res));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(deleteCarrierError());
      });
  };
};

function deleteCarrierRequest(data) {
  return {
    type: DELETE_CARRIERS_REQUEST,
    payload: data,
  };
}
function deleteCarrierSuccess(data) {
  return {
    type: DELETE_CARRIERS_SUCCESS,
    payload: data,
  };
}
function deleteCarrierError(data) {
  return {
    type: DELETE_CARRIERS_ERROR,
    payload: data,
  };
}

// Add Carrier API CAll

export const requestToAddCarriers = (payload) => {
  const { carrier, carrierToReplaceId, reason, applyRule, country, shopId } =
    payload;
  return (dispatch) => {
    dispatch(addCarrierRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.addCarrier(shopId)}`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        id: 0,
        carrier: carrier,
        carrierToReplaceId: carrierToReplaceId,
        reason: reason,
        createdAt: "2023-05-01T13:57:41.202Z",
        country: country,
        applyRule: applyRule,
      }),
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(addCarrierSuccess(res));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(addCarrierError());
      });
  };
};

function addCarrierRequest(data) {
  return {
    type: ADD_CARRIERS_REQUEST,
    payload: data,
  };
}
function addCarrierSuccess(data) {
  return {
    type: ADD_CARRIERS_SUCCESS,
    payload: data,
  };
}
function addCarrierError(data) {
  return {
    type: ADD_CARRIERS_ERROR,
    payload: data,
  };
}
