import { React, useState } from "react";
import { Button, ButtonGroup, Layout } from "@shopify/polaris";
import FullfilmentChecker from "../segmentedTabs/FullfilmentChecker";
import PaypalChecker from "../segmentedTabs/PaypalChecker";
import Processes from "../segmentedTabs/Processes";

const SecondButtonSegment = () => {
  const [activeSegment, setActiveSegment] = useState(0);
  const [tab, setTab] = useState(<Processes />);
  const handleSegment = (active, tabName) => {
    switch (tabName) {
      case "fulfillmentchecker":
        setTab(<FullfilmentChecker />);
        break;

      case "paypalchecker":
        setTab(<PaypalChecker />);
        break;

      case "processes":
        setTab(<Processes />);
        break;

      default:
        break;
    }
    setActiveSegment(active);
  };
  return (
    <>
      <Layout.Section>
        <div className="wrapper d-flex flex-col gap-10">
          <div>
            <ButtonGroup fullWidth={true} segmented>
              <Button
                primary={activeSegment === 0 ? true : false}
                onClick={() => {
                  handleSegment(0, "processes");
                }}
              >
                Processes
              </Button>
              <Button
                primary={activeSegment === 1 ? true : false}
                onClick={() => {
                  handleSegment(1, "fulfillmentchecker");
                }}
              >
                Fulfillment checker
              </Button>
              <Button
                primary={activeSegment === 2 ? true : false}
                onClick={() => {
                  handleSegment(2, "paypalchecker");
                }}
              >
                Paypal checker
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Layout.Section>
      {tab}
    </>
  );
};

export default SecondButtonSegment;
