import {IndexTable, LegacyCard,} from "@shopify/polaris";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {reportsApi} from "../../../redux/reportReducer/Action";

const Table = () => {
  const navigate = useNavigate();
  const [row, setRow] = useState([]);
  const dispatch = useDispatch();
  const reportTable = useSelector((store) => store.requriedReportData);

  useEffect(() => {
    dispatch(reportsApi());
  }, []);
  useEffect(() => {
    if (reportTable.data?.content) {
      setRow(reportTable.data.content); }
    if(reportTable?.data?.message === "Actual error message") {
      navigate('/logout')
    }
  }, [reportTable]);
  

  const date = (
    <span className="d-flex gap-10">
      <span className="table_heading">Date</span>
      
    </span>
  );

  const syncOrder = (
    <span className="d-flex gap-10">
      <span className="table_heading">Synced Orders</span>
     
    </span>
  );

  const value = (
    <span className="d-flex gap-10">
      <span className="table_heading">Value</span>
      
    </span>
  );

  const rowMarkup = row.map(({ date, count, sum }, index) => (
    <IndexTable.Row>
      <IndexTable.Cell><p style={{padding:"10px"}}>{date}</p></IndexTable.Cell>
      <IndexTable.Cell>{count}</IndexTable.Cell>
      <IndexTable.Cell>{sum}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  return (
    <div className="mt-1">
      <LegacyCard>
        <IndexTable
          resourceName={resourceName || 0}
          itemCount={row.length}
          headings={[
            {id:"1", title: date },
            { id:"2", title: syncOrder },
            {id:"3", title: value },
          ]}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      </LegacyCard>
    </div>
  );
};

export default Table;
