import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import {
  CACHE_SYNC_ERROR,
  CACHE_SYNC_REQUEST,
  CACHE_SYNC_SUCCESS,
} from "./ActionType";

export const cacheSyncForShop = (shopId) => {
  return async (dispatch) => {
    await dispatch(cacheSyncRequest());
    const url = `${
      process.env.REACT_APP_BACKEND_API_URL
    }${ROUTES_NAME.cacheSync(shopId)}`;
    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        dispatch(cacheSyncSuccess(resp));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(cacheSyncError());
      });
  };
};

function cacheSyncRequest(data) {
  return {
    type: CACHE_SYNC_REQUEST,
    payload: data,
  };
}

function cacheSyncSuccess(data) {
  return {
    type: CACHE_SYNC_SUCCESS,
    payload: data,
  };
}

function cacheSyncError(data) {
  return {
    type: CACHE_SYNC_ERROR,
    payload: data,
  };
}
