export const ROUTES_NAME = {
  dashboard: "/dashboard",
  profile: "/dashboard/profile",
  dashboardTotalSyncedYesterday:"/dashboard/totalSyncedYesterday",
  dashboardTotalSyncedToday:"/dashboard/totalSyncedToday",
  dashboardTotalSyncedLastTwoDays:"/dashboard/totalSyncedLastTwoDays",
  dashboardTotalSyncedLastThirtyDays:"/dashboard/totalSyncedLastThirtyDays",
  dashboardTotalSyncedLastSevenDays:"/dashboard/totalSyncedLastSevenDays",
  dashboardTotalStores:"/dashboard/totalStores",
  dashboardTotalPaypalUploads:"/dashboard/totalPaypalUploads",
  dashboardTotalOrders:"/dashboard/totalOrders",
  dashboardTotalActive:"/dashboard/totalActive",
  dashboardprofile:"/dashboard/profile",
  dashboardAwaitingSync:"/dashboard/awaitingSync",
  table:"/stores/advanced-search",
  paypalSetting:"/paypal/settings",
  pullLogs:"/pull-logs",
  report: "/reports/performances",
  carrier:"/carriers",
  events:"/stores/events",
  find_detail:"/stores/find-detail",
  stats:"/stores/stats",
  processes:"/stores/processes",
  processes_queue:"/stores/processes/queue",
  shop_countries: `/stores/list-country`,
  all_countries: ()=> `/store/routing-policy/all-countries`,
  networth:(shopId)=>`/store/dashboard/networth?shopId=${shopId}`,
  synced : (shopId) => `/store/dashboard/synced?shopId=${shopId}`,
  synced_last_ten_days: (shopId) => `/store/dashboard/synced-last-10days?shopId=${shopId}`,
  received_last_ten_days :(shopId) => `/store/dashboard/received-last-10days?shopId=${shopId}`,
  linked_paypal_by_shop: (shopId) => `/store/dashboard/linked-paypal-by-shop?shopId=${shopId}`,
  dashboard_all:(shopId) => `/store/dashboard/all?shopId=${shopId}`,
  failed:(shopId) => `/store/dashboard/failed?shopId=${shopId}`,
  missing : (shopId) => `/store/dashboard/missing?shopId=${shopId}`,
  queued:(shopId) => `/store/dashboard/queued?shopId=${shopId}`,
  pending: (shopId) => `/store/dashboard/pending?shopId=${shopId}`,
  invalid_gateway: (shopId) => `/store/dashboard/invalid-gateway?shopId=${shopId}`,
  advanceSearch : (shopId) => `/store/order/advanced-search?shopId=${shopId}`,
  resync: (shopId) => `/store/order/re-sync-orders?shopId=${shopId}`,
  historicalStatus : (shopId) => `/store/historical/status?shopId=${shopId}`,
  storeQueue : (id ) => `/store/historical/queue?shopId=${id}`,
  storeAllCarrier : (shopId) => `/store/routing-policy/all-carriers?shopId=${shopId}`,
  storeAllGeoCarrier : (shopId) => `/store/routing-policy/list-carriers-by-geo?shopId=${shopId}`,
  deleteCarrier : (shopId) => `/store/routing-policy/delete-paypal-carrier?shopId=${shopId}`,
  addCarrier : (shopId) => `/store/routing-policy/add-paypal-carrier?shopId=${shopId}`,
  historicalCount : (shopId) => `/store/historical/count?shopId=${shopId}`,
  paypal_setting_default : (payload) => `/store/paypal-settings/default?payPalSettingId=${payload}`,
  paypal_setting_delete : (payload) => `/store/paypal-settings/delete?payPalSettingId=${payload}`,
  digital_sync : (shopId) => `/store/setting/digital-sync?shopId=${shopId}`,
  courier_mapping : (shopId) => `/store/setting/courrier-mapping?shopId=${shopId}`,
  find_by_id : (shopId) => `/store/setting/find-by-id?shopId=${shopId}`,
  fullfillment :() => `/checker/fulfillment`,
  paypalChecker : ()=> `/checker/paypal`,
  fetch_by_name: "/checker/fetch-by-name",
  possible_orders: (shopId) => `/store/historical/order-count?shopId=${shopId}`,
  reindexing : (id) => `/reindex?shopId=${id}`,
  cancelDigitalOrder: (id) => `/reindex/cancel-digital?shopId=${id}`,
  cacheSync : (id) => `/cache/sync?shopId=${id}`,
  troubleshoot_totalOrders: (id) => `/stores/totalOrders?shopId=${id}`,
  troubleshoot_awaitingSync: (id) => `/stores/awaitingSync?shopId=${id}`,
  troubleshoot_totalSynced: (id) => `/stores/totalSynced?shopId=${id}`,
  gdpr: (gdprActionType, page, pageSize ) => `/gdpr?gdprActionType=${gdprActionType}&page=${page}&pageSize=${pageSize}`
};