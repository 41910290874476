import {
  Box,
  DatePicker,
  Icon,
  Popover,
  TextField,
  VerticalStack,
  LegacyCard,
  Button,
} from "@shopify/polaris";
import { React, useEffect, useRef, useState } from "react";
import { CalendarMinor } from "@shopify/polaris-icons";
import moment from "moment";
import { gettotalPossibleOrders } from "../../redux/store/historicalOrders/Action";
import { OrdersCount } from "../popup";
import { useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const DatePickerComponent = (props) => {
  const [state, setState] = useState(false);
  const [openPopup, setOpenPopup] = useState(false)
  const [orders, setOrders] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const totalPossibleOrders = useSelector(
    (store) => store.requiredHistoricalData
  );


  function nodeContainsDescendant(rootNode, descendant) {
    if (rootNode === descendant) {
      return true;
    }
    let parent = descendant.parentNode;
    while (parent != null) {
      if (parent === rootNode) {
        return true;
      }
      parent = parent.parentNode;
    }
    return false;
  }
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [{ month, year }, setDate] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });
  const formattedValue = moment(selectedDate).format("YYYY-MM-DD");
  const datePickerRef = useRef(null);
  function isNodeWithinPopover(node) {
    return datePickerRef?.current
      ? nodeContainsDescendant(datePickerRef.current, node)
      : false;
  }
  function handleInputValueChange() {
    console.log("handleInputValueChange");
  }
  function handleOnClose({ relatedTarget }) {
    setVisible(false);
  }
  function handleMonthChange(month, year) {
    setDate({ month, year });
  }
  function handleDateSelection({ end: newSelectedDate }) {
    setSelectedDate(newSelectedDate);
    setVisible(false);
    sessionStorage.setItem("date", formattedValue);
  }
  useEffect(() => {
    if (selectedDate) {
      setDate({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      });
    }
  }, [selectedDate]);

  const handleTotalOrdersCount = (date) => {
    const payload = {
      shopId: id,
      date: date
    };
    dispatch(gettotalPossibleOrders(payload));
  };
  useEffect(() => {
    if (
      totalPossibleOrders.isSuccess &&
      totalPossibleOrders?.possibleOrdersCount?.count !== undefined && openPopup
    ) {
      setOrders(totalPossibleOrders.possibleOrdersCount.count);
      setState(true);
    }
  }, [totalPossibleOrders]);

  return (
    <>
      {state ? (
        <OrdersCount
          formattedValue={formattedValue}
          orders={orders}
          deletedAlert={state}
          setDeletedAlert={setState}
          handleOrders={props.handleHistoricalOrdersQueue}
          setOpenPopup={setOpenPopup}
        />
      ) : (
        ""
      )}
      <VerticalStack inlineAlign="center" gap="4">
        <Box minWidth="276px" padding={{ xs: 2 }}>
          <div className="d-flex gap-20 flex-wrap">
            <Popover
              active={visible}
              autofocusTarget="none"
              preferredAlignment="left"
              fullWidth
              preferInputActivator={false}
              preferredPosition="below"
              preventCloseOnChildOverlayClick
              onClose={handleOnClose}
              activator={
                <TextField
                  role="combobox"
                  label={""}
                  prefix={<Icon source={CalendarMinor} />}
                  value={formattedValue}
                  onFocus={() => setVisible(true)}
                  onChange={handleInputValueChange}
                  autoComplete="off"
                />
              }
            >
              <LegacyCard ref={datePickerRef}>
                <DatePicker
                  month={month}
                  year={year}
                  selected={selectedDate}
                  onMonthChange={handleMonthChange}
                  onChange={handleDateSelection}
                />
              </LegacyCard>
            </Popover>
            <Button
              primary
              onClick={() => {
                handleTotalOrdersCount(formattedValue);
                setOpenPopup(true)
              }}
              loading={totalPossibleOrders.isRequest}
            >
              Submit request
            </Button>
          </div>
        </Box>
      </VerticalStack>
    </>
  );
};

export default DatePickerComponent;
