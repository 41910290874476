import { ROUTES_NAME } from "../../../config/constants";
import { ACCESS_TOKEN } from "../../../constants";
import { TOTAL_ORDERS_SUCCESS, TOTAL_ORDERS_ERROR, TOTAL_ORDERS_REQUEST, AWAITING_SYNC_ORDERS_ERROR, AWAITING_SYNC_ORDERS_REQUEST, AWAITING_SYNC_ORDERS_SUCCESS, TOTAL_SYNC_ORDERS_ERROR, TOTAL_SYNC_ORDERS_REQUEST, TOTAL_SYNC_ORDERS_SUCCESS } from "./ActionType";


export const getTroubleShootTotalOrders = (id) => {
  return (dispatch) => {
    dispatch(totalOrdersrequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.troubleshoot_totalOrders(id)}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(totalOrdersSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(totalOrdersError());
      });
  };
};

function totalOrdersrequest(dashboardData) {
  return {
    type: TOTAL_ORDERS_REQUEST,
    payload: dashboardData,
  };
}
function totalOrdersSuccess(dashboardData) {
  return {
    type: TOTAL_ORDERS_SUCCESS,
    payload: dashboardData,
  };
}
function totalOrdersError(dashboardData) {
  return {
    type: TOTAL_ORDERS_ERROR,
    payload: dashboardData,
  };
}

//////

export const getTroubleShootAwaitingSyncOrders = (id) => {
  return (dispatch) => {
    dispatch(awaitingOrdersRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.troubleshoot_awaitingSync(id)}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(awaitingOrdersSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(awaitingOrdersError());
      });
  };
};

function awaitingOrdersRequest(dashboardData) {
  return {
    type: AWAITING_SYNC_ORDERS_REQUEST,
    payload: dashboardData,
  };
}
function awaitingOrdersSuccess(dashboardData) {
  return {
    type: AWAITING_SYNC_ORDERS_SUCCESS,
    payload: dashboardData,
  };
}
function awaitingOrdersError(dashboardData) {
  return {
    type: AWAITING_SYNC_ORDERS_ERROR,
    payload: dashboardData,
  };
}

////


export const getTroubleShootTotalSynced = (id) => {
  return (dispatch) => {
    dispatch(totalSyncedRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.troubleshoot_totalSynced(id)}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((dashboardDetails) => {
        dispatch(totalSyncedSuccess(dashboardDetails));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(totalSyncedError());
      });
  };
};

function totalSyncedRequest(dashboardData) {
  return {
    type: TOTAL_SYNC_ORDERS_REQUEST,
    payload: dashboardData,
  };
}
function totalSyncedSuccess(dashboardData) {
  return {
    type: TOTAL_SYNC_ORDERS_SUCCESS,
    payload: dashboardData,
  };
}
function totalSyncedError(dashboardData) {
  return {
    type: TOTAL_SYNC_ORDERS_ERROR,
    payload: dashboardData,

  };
}
