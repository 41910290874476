import Main from "./main";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useState} from "react";
import LoginPage from "./pages/login/Login";
import OAuth2RedirectHandler from "./login/OAuth2RedirectHandler";
import Profile from "./login/Profile";
import React from "react";

import NotFound from "./common/NotFound";
import { ACCESS_TOKEN } from "./constants";
import Logout from "./components/logout/Logout";

const App = () => {
   const [state, setState] = useState("");
   const [authenticated, setAuthenticated] = useState(false);
   const [currentUser, setCurrentUser] = useState(null);
   const [loading, setLoading] = useState(false);
    const handleLogout  =()=> {
        localStorage.removeItem(ACCESS_TOKEN);
        this.setState({
          authenticated: false,
          currentUser: null
        });
       console.log("You're safely logged out!");
      }



  return (
    <div className="app">
            <div className="app-top-box">
            </div>
            <div className="app-body">
            <Routes>
                <Route exact path="/"
                element={<LoginPage />}
                 >
                </Route>
                <Route path="/logout" element={<Logout />} />
                <Route
                  path="/profile"
                  component={Profile}
                ></Route>
                <Route
                  path="/login"
                  element={<LoginPage />}
                ></Route>
                <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />}></Route>
                <Route component={NotFound}></Route>
                </Routes>
                <Main state={state} setState={setState}/>
            </div>
          </div>
  );
}

export default App;
