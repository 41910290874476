import { ROUTES_NAME } from "../../config/constants";
import { ACCESS_TOKEN } from "../../constants";
import { GDPR_REQUEST, GDPR_SUCCESS, GDPR_ERROR } from "./ActionType";

export const getgdprData = (payload) => {
  const { type, page } = payload;
  return async (dispatch) => {
    dispatch(gdprSearchRequest());
     const pageSize = 10;
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.gdpr(
      type,page,pageSize
    )}`;

    await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        "Content-Type": "application/json",
        Origin: `${process.env.REACT_APP_URL}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(gdprSearchSuccess(data));
      })
      .catch((data) => {
        console.log(data, "error");
        dispatch(gdpSearchError());
      });
  };
};

function gdprSearchRequest(receivedData) {
  return {
    type: GDPR_REQUEST,
    payload: receivedData,
  };
}

function gdprSearchSuccess(receivedData) {
  return {
    type: GDPR_SUCCESS,
    payload: receivedData,
  };
}

function gdpSearchError(receivedData) {
  return {
    type: GDPR_ERROR,
    payload: receivedData,
  };
}
