import React, { useEffect, useState } from "react";
import {
  Layout,
  LegacyCard,
  Badge,
  Button,
  Divider,
  Icon,
  Image,
  Banner, 
} from "@shopify/polaris";
import {
  CustomersMajor,
  ProfileMajor,
  GlobeMajor,
  ComposeMajor,
  EmailMajor,
  CalendarMajor,
} from "@shopify/polaris-icons";
import "../style.css";
import { useSelector, useDispatch } from "react-redux";
import { findByIdApi } from "../../../redux/troubleshooting/clientDetails/Action";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import StoreCustomBadge from "../../../components/customBadge/StoreCustomBadge";
import { getReIndexStore, postCancelDigitalOrder } from "../../../redux/troubleshooting/reindex/Action";
import { cacheSyncForShop } from "../../../redux/troubleshooting/cache-sync/Action";

const ClientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [clientDetails, setClientDetails] = useState([]);
  const [subscribed, setSubscribed] = useState();
  const [infoBanner, setInfoBanner] = useState(false);
  const [cancelDigitalOderBanner, setCancelDigitalOderBanner] = useState(false);
  const [show, setShow] = useState(false);
  const [cancelDigitalOrdersCheck, setCancelDigitalOrdersCheck] = useState(false);
  const dispatch = useDispatch();
  const findClientsDetails = useSelector((store) => store.requriedFindDetails);
  const reindexStore = useSelector((store) => store.requiredReIndexReducer);

  useEffect(() => {
    dispatch(findByIdApi(id));
  }, []);
  useEffect(() => {
    if (findClientsDetails.data) {
      setClientDetails(findClientsDetails.data);
      const specificMoment = moment(findClientsDetails.data.createdAt);
      const currentDate = moment();
      const duration = moment.duration(currentDate.diff(specificMoment));
      const daysDiff = duration.asDays();
      setSubscribed(daysDiff);
    }
    if (findClientsDetails?.data?.message === "Actual error message") {
      navigate("/logout");
    }
  }, [findClientsDetails]);


  useEffect(() => {
    if (reindexStore.isSuccess && show) {
      setInfoBanner(true);
    } else setInfoBanner(false);

    if(reindexStore?.isSuccess && cancelDigitalOrdersCheck) {
      setCancelDigitalOderBanner(true);
    } else setCancelDigitalOderBanner(false);
  }, [reindexStore]);

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const handleRedindexStore = () => {
    setShow(true);
    dispatch(getReIndexStore(id));
  };

  const handleCancelDigitalOrders = () => {
    setCancelDigitalOrdersCheck(true)
    dispatch(postCancelDigitalOrder(id));
  }

  const handleCacheSync = () => {
   dispatch(cacheSyncForShop(id));
  }

  return (
    <>
      <Layout.Section>
        <div className="wrapper">
          {infoBanner ? (
            <div className="mb-1">
              <Banner
                title="Request for reindexing submitted."
                status="info"
                onDismiss={() => {
                  setInfoBanner(false);
                }}
              ></Banner>
            </div>
          ) : (
            ""
          )}

          {cancelDigitalOderBanner && (
            <div className="mb-1">
              <Banner
                title="Request for Cancelling Digital Orders Submitted."
                status="info"
                onDismiss={() => {
                  setCancelDigitalOderBanner(false);
                }}
              ></Banner>
            </div>
          )}

          <LegacyCard sectioned>
            <div style={{ margin: "17px" }}>
              <div className="d-flex  flex_wrap justify-content-between align-item-center mt-1 gap-10">
                <div className="d-flex flex_wrap gap-20 ">
                  <p className="title">Client account</p>
                  <div>
                    {/* <Badge>In trial</Badge> */}
                    {subscribed < 7 ? (
                      <StoreCustomBadge
                        textcolor="#000000"
                        text="In trial"
                        color="#A4E8F2"
                      />
                    ) : null}
                  </div>
                  <div>
                    <Badge
                      status={clientDetails.state ? "success" : "critical"}
                    >
                      Shopify Integration
                    </Badge>
                  </div>
                  <div>
                    <Badge
                      status={
                        clientDetails.paypalActive ? "success" : "critical"
                      }
                    >
                      Paypal
                    </Badge>
                  </div>
                  <div>
                    <Badge
                      status={
                        clientDetails.recurringChargeActive
                          ? "success"
                          : "critical"
                      }
                    >
                      Valid Charge
                    </Badge>
                  </div>
                  {clientDetails.plan ? (
                    <div>
                      <Badge status="success">{clientDetails.plan}</Badge>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex gap-10">
                  <a
                    className="linkContainer"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://partners.shopify.com/1857043/stores/${clientDetails.externalId}`}
                  >
                    <Button primary>View in Shopify</Button>
                  </a>
                  <Button
                    onClick={() => {
                      handleRedindexStore();
                    }}
                    primary
                  >
                    Reindex store
                  </Button>
                  <Button onClick={()=> {handleCancelDigitalOrders()}} primary>Cancel Digital Orders</Button>
                  <Button onClick={()=> {handleCacheSync()}} primary>Redis</Button>
                </div>
              </div>
              <div className="mt-2 mb-2">
                <Divider />
              </div>
              <div
                className="d-flex flex_wrap"
                style={{ alignItems: "baseline", gap: "20px" }}
              >
                <div
                  className="d-flex flex_wrap flex-col"
                  style={{ gap: "15px", width: "50%" }}
                >
                  <div className="d-flex  flex_wrap gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CustomersMajor} />
                      <p className="user_value">Name:</p>
                    </div>
                    <p className="user_key">{clientDetails.name}</p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={ProfileMajor} />
                      <p className="user_value">Owner:</p>
                    </div>
                    <p className="user_key">{clientDetails.shopOwner}</p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={GlobeMajor} />
                      <p className="user_value">Country:</p>
                    </div>
                    <div className="d-flex">
                      {clientDetails.countryCode === null ||
                      clientDetails.countryCode === undefined ? (
                        ""
                      ) : (
                        <div>
                          <Image
                            width={15}
                            source={`https://flagcdn.com/48x36/${clientDetails.countryCode.toLowerCase()}.png`}
                            style={{ marginRight: "5px" }}
                          />
                        </div>
                      )}
                      <p className="user_key">{clientDetails.countryName}</p>
                    </div>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={ComposeMajor} />
                      <p className="user_value">Domain:</p>
                    </div>
                    <p className="user_key">{clientDetails.domain}</p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={EmailMajor} />
                      <p className="user_value">Email:</p>
                    </div>
                    <p className="user_key">{clientDetails.email}</p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CustomersMajor} />
                      <p className="user_value">Tokens left:</p>
                    </div>
                    <p className="user_key">{clientDetails.totalTokens}</p>
                  </div>
                </div>
                {/* second half of details */}
                <div className="d-flex flex-col" style={{ gap: "15px" }}>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CalendarMajor} />
                      <p className="user_value">Created:</p>
                    </div>
                    <p className="user_key">
                      {clientDetails.createdAt === null ||
                      clientDetails.createdAt === undefined
                        ? ""
                        : formatDateTime(clientDetails.createdAt)}
                    </p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CalendarMajor} />
                      <p className="user_value">Subscribed:</p>
                    </div>
                    <p className="user_key">
                      {clientDetails.chargeFirstAcceptedAt === null ||
                      clientDetails.chargeFirstAcceptedAt === undefined
                        ? ""
                        : formatDateTime(clientDetails.chargeFirstAcceptedAt)}
                    </p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CalendarMajor} />
                      <p className="user_value">Last pull:</p>
                    </div>
                    <p className="user_key">
                      {clientDetails.lastImportAt === null ||
                      clientDetails.lastImportAt === undefined
                        ? ""
                        : formatDateTime(clientDetails.lastImportAt)}
                    </p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CalendarMajor} />
                      <p className="user_value">Last sync:</p>
                    </div>
                    <p className="user_key">
                      {clientDetails.lastSyncAt === null ||
                      clientDetails.lastSyncAt === undefined
                        ? ""
                        : formatDateTime(clientDetails.lastSyncAt)}
                    </p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CalendarMajor} />
                      <p className="user_value">Pull deactivated at:</p>
                    </div>
                    <p className="user_key">
                      {clientDetails.deactivatedAt === null ||
                      clientDetails.deactivatedAt === undefined
                        ? ""
                        : formatDateTime(clientDetails.deactivatedAt)}
                    </p>
                  </div>
                  <div className="d-flex gap-20">
                    <div className="d-flex gap-10">
                      <Icon color="subdued" source={CalendarMajor} />
                      <p className="user_value">Deactivation reason:</p>
                    </div>
                    <p className="user_key">
                      {clientDetails.deactivationReason === null ||
                      clientDetails.deactivationReason === undefined
                        ? ""
                        : stripHtmlTags(clientDetails.deactivationReason)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LegacyCard>
        </div>
      </Layout.Section>
    </>
  );
};

export default ClientDetails;
