import React, { useState } from "react";
import "./style.css";
import {
  Badge,
  IndexTable,
  LegacyCard,
  Link,
  SkeletonBodyText,
  Text,
  Tooltip,
  Image,
  EmptySearchResult,
} from "@shopify/polaris";
import CustomBadge from "../../../components/customBadge";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { StatusDiscription, WarningInfo } from "../../../utils/WarningInfo";
import { Warning, DangerWarning } from "../../../assets";

const Table = (props) => {
  const { t, i18n } = useTranslation();

  const emptyStateMarkup = (
    <EmptySearchResult 
      title={t("No_orders_found")}
      description={t("filter_message")}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }

  const resourceName = {
    singular: "orders",
    plural: "orders",
  };

  const rowMarkup = props?.customers.map(
    (
      {
        carrier,
        name,
        storeOrderId,
        transactionId,
        fulfillmentId,
        paypalCarrier,
        orderStatus,
        processedAt,
        createdAt,
        trackingNumber,
        statusMessage,
        statusDescription,
        gateway,
        timeToSync,
        processingMethod,
        merchantAccountId
      },
      index
    ) => (
      <IndexTable.Row id={fulfillmentId} key={fulfillmentId} position={name}>
        <IndexTable.Cell>
          <p className="">
            <a
              style={{ color: "#315db0" }}
              href={`https://integration.palsync.com/orders/edit/${storeOrderId}`}
              target="_blank"
            >
              {storeOrderId}
            </a>
          </p>
          <p className="table-data-secondary">{name}</p>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p className="">
            <a
              style={{ color: "#315db0" }}
              href={`https://www.paypal.com/activity/payment/${transactionId}`}
              target="_blank"
            >
              {transactionId}
            </a>
          </p>
          <Text>
            <p className="table-data-secondary">{gateway}</p>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p className="table-data">
            <a
              style={{ color: "#315db0" }}
              href={`https://www.trackingmore.com/en/${trackingNumber}`}
              target="_blank"
            >
              {trackingNumber}
            </a>
          </p>
          <Text variant="bodyMd" as="span" alignment="" numeric>
            {(paypalCarrier !== "OTHER"  && paypalCarrier !== carrier) ?
            (<p className="table-data-secondary">{carrier} -> {paypalCarrier}</p>)
            : (<p className="table-data-secondary">{carrier}</p>) }
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          {orderStatus === "SUCCESS" ? (
            statusDescription ? (
              <Tooltip
                content={
                  <div className="d-flex flex-col gap-10">
                    <p className="warningDiscription">
                      {t(
                        WarningInfo(statusDescription)
                          ?.languageConstStatusMessage
                      )}
                    </p>
                  </div>
                }
              >
                {console.log("successs tooltip",orderStatus)}
                <Badge status="success" as="span" progress="complete">
                  <p className="table-data">Success</p>
                </Badge>
              </Tooltip>
            ) : (
              <Badge status="success" as="span" progress="complete">
                {console.log("successs tooltip without",orderStatus)}
                <p className="table-data">Success</p>
              </Badge>
            )
          ) : orderStatus === "QUEUED" ? (
            <CustomBadge
              text="Queued"
              dot="#fff"
              textColor="#fff"
              color="#0000FF"
            />

          ) : orderStatus === "MISSING_INFO" &&
            (processingMethod === "MISSING_DEFAULT_MERCHANT_ACCOUNT_ID" ||
              processingMethod === "MISSING_MERCHANT_ACCOUNT_ID") ? (
            statusMessage || statusDescription ? (
              <div className="d-flex gap-10 aligns-Items-center ">
                {console.log("MISSING_INFO with tooltip",orderStatus)}
                <CustomBadge
                  text="Paypal not connected"
                  color="#FFD79D"
                  dot="#B98900"
                  textColor="black"
                />
                <Tooltip
                  content={
                    <div className="d-flex flex-col gap-10">
                      <p className="warningHeading">
                        {t(
                          WarningInfo(statusMessage)?.languageConstStatusMessage
                        )}
                      </p>
                      {/* <p className="warningDiscription">
                        {t(StatusDiscription(statusDescription))}
                      </p> */}
                      <p className="warningDiscription">
                        {statusDescription.includes(
                          "is not connected to Trackipal"
                        )
                          ? t("Merchant_ID_is_not_connected_to_Trackipal", {
                            merchantAccountId,
                          })
                          : t(StatusDiscription(statusDescription)?.languageConstStatusDiscription)}
                      </p>
                    </div>
                  }
                >
                  <Image src={Warning} className="mt-5" width={20} />
                </Tooltip>
              </div>
            ) : (
              <CustomBadge
                text="Paypal not connected"
                color="#FFD79D"
                dot="#B98900"
                textColor="black"
              />
            )
          ) : (orderStatus === "MISSING_INFO" &&
            processingMethod === "MISSING_TRACKING_NUMBER") ||
            processingMethod === "MISSING_CARRIER" ||
            processingMethod === "MISSING_TRANSACTION_ID" ? (
            statusMessage || statusDescription ? (

              <div className="d-flex gap-10 aligns-Items-center ">
                {console.log("MISSING_INFO with tooltip",orderStatus)}

                <CustomBadge
                  text="Unfullfilled"
                  color="#FFD79D"
                  dot="#B98900"
                  textColor="black"
                />
                <Tooltip
                  content={
                    <div className="d-flex flex-col gap-10">
                      <p className="warningHeading">
                        {t(
                          WarningInfo(statusMessage)?.languageConstStatusMessage
                        )}
                      </p>
                      <p className="warningDiscription">
                        {t(
                          StatusDiscription(statusDescription)
                            ?.languageConstStatusDiscription
                        )}
                      </p>
                    </div>
                  }
                >
                  <Image src={Warning} className="mt-5" width={20} />
                </Tooltip>
              </div>
            ) : (
              <CustomBadge
                text="Unfullfilled"
                color="#FFD79D"
                dot="#B98900"
                textColor="black"
              />
            )
          ) : orderStatus === "PROCESSING" ? (
            <CustomBadge

              text="Processing"
              color="#2a52be"
              dot="#ffffff"
              textColor="#ffffff"
            />
          ) : orderStatus === "FAILED" ? (

            statusMessage || statusDescription ? (
              <div className="d-flex gap-10 aligns-Items-center ">
                {console.log("FAILED with tooltip",orderStatus)}

                <Badge status="critical" as="span" progress="complete">
                  <p className="table-data">Failed</p>
                </Badge>
                <Tooltip
                  content={
                    <div className="d-flex flex-col gap-10">
                      <p className="warningHeading" style={{ color: "red" }}>
                        {t(
                          WarningInfo(statusMessage)?.languageConstStatusMessage
                        )}
                      </p>
                      <p className="warningDiscription">
                        {t(
                          StatusDiscription(statusDescription)
                            ?.languageConstStatusDiscription
                        )}
                      </p>
                    </div>
                  }
                >
                  <Image src={DangerWarning} className="mt-5" width={20} />
                </Tooltip>
              </div>
            ) : (
              <Badge status="critical" as="span" progress="complete">
                {console.log("failed without tooltip",orderStatus)}
                <p className="table-data">Failed</p>
              </Badge>
            )
          ) : orderStatus === "INVALID_GATEWAY" ? (

            statusMessage || statusDescription ? (
              <div className="d-flex gap-10 aligns-Items-center ">
                {console.log("INVALID_GATEWAY with tooltip",orderStatus)}

                <CustomBadge
                  text="Invalid Gateway"
                  color="#FFD79D"
                  dot="#B98900"
                  textColor="black"
                />
                <Tooltip
                  content={
                    <div className="d-flex flex-col gap-10">
                      <p className="warningHeading">
                        {t(
                          WarningInfo(statusMessage)?.languageConstStatusMessage
                        )}
                      </p>
                      <p className="warningDiscription">
                        {t(
                          StatusDiscription(statusDescription)
                            ?.languageConstStatusDiscription
                        )}
                      </p>
                    </div>
                  }
                >
                  <Image src={DangerWarning} className="mt-5" width={20} />
                </Tooltip>
              </div>
            ) : (
              <CustomBadge
                text="Invalid Gateway"
                color="#FFD79D"
                dot="#B98900"
                textColor="black"
              />
            )
          ) : (
            ""
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {processedAt === null ? "" : formatDateTime(processedAt)}
        </IndexTable.Cell>
        <IndexTable.Cell>{formatDateTime(createdAt)}</IndexTable.Cell>
        <IndexTable.Cell>
          {timeToSync === null ? "" : timeToSync}
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const details = (
    <p key="details" className="table_heading">
      {t("Details")}
    </p>
  );
  const tranaction = (
    <p key="tranaction" className="table_heading">
      {t("Transaction")}
    </p>
  );
  const courier = (
    <p key="courier" className="table_heading">
      {t("Courier")}
    </p>
  );
  const status = (
    <p key="status" className="table_heading">
      {t("Status")}
    </p>
  );
  const DateTime = (
    <p key="DateTime" className="table_heading">
      {t("DateAndtime")}
    </p>
  );
  const FullFilledAt = <div className="table_heading">{t("FullfilledAt")}</div>;
  const timeToSync = <div className="table_heading">{t("SyncTime")}</div>

  return (
    <>
      <div style={{ marginTop: "20px", padding: "" }}>
        <LegacyCard title="Orders" sectioned>
          <LegacyCard.Section>
            <IndexTable
              resourceName={resourceName || 0}
              itemCount={props.customers.length}
              emptyState={emptyStateMarkup}
              headings={[
                { id: "details", title: details },
                { id: "tranaction", title: tranaction },
                { id: "courier", title: courier },
                { id: "status", title: status },
                { id: "DateTime", title: DateTime },
                { id: "FullFilledAt", title: FullFilledAt },
                { id: "timeToSync", title: timeToSync },
              ]}
              selectable={false}
            >
              {rowMarkup}
            </IndexTable>
          </LegacyCard.Section>
        </LegacyCard>
      </div>
    </>
  );
};

export default Table;
