import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Badge,
  Button,
  IndexTable,
  LegacyCard,
  Text,
  Tooltip,
  Image,
  EmptySearchResult,
} from "@shopify/polaris";
import { getAdvanceSearchData } from "../../../redux/store/dashboard/Action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomBadge from "../../../components/customBadge";
import moment from "moment";
import { TableSkeleton } from "../../../components/skeletonLoader";
import { Warning, DangerWarning } from "../../../assets";
import { WarningInfo, StatusDiscription } from "../../../utils/WarningInfo";
import { useTranslation } from "react-i18next";

const Table = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const OrdersTableData = useSelector(
    (store) => store.requiredStoreDashboardData
  );
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    const payload = {
      page: 0,
      shopId: id,
      ordersStatus: null,
      search: "",
      startDate: null,
      endDate: null
    };
    dispatch(getAdvanceSearchData(payload));
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      OrdersTableData &&
      OrdersTableData?.advanceSearch !== undefined &&
      OrdersTableData?.advanceSearch?.shopifyFulfillmentActiveResponses !==
        undefined
    ) {
      setCustomers(
        OrdersTableData.advanceSearch.shopifyFulfillmentActiveResponses
      );
    }
    if (OrdersTableData?.advanceSearch?.message === "Actual error message") {
      navigate("/logout");
    }
  }, [OrdersTableData]);

  const emptyStateMarkup = (
    <EmptySearchResult
      title={t("No_orders_found")}
      description={t("filter_message")}
      withIllustration
    />
  );

  function formatDateTime(datetime) {
    try {
      const date = moment(datetime);
      const formattedDate = date.format("MMM DD, YYYY, h:mm A");
      return formattedDate;
    } catch (err) {
      return "";
    }
  }
  const resourceName = {
    singular: "orders",
    plural: "orders",
  };

  const rowMarkup = customers.map(
    (
      {
        carrier,
        name,
        storeOrderId,
        transactionId,
        orderStatus,
        createdAt,
        trackingNumber,
        gateway,
        processingMethod,
        statusMessage,
        statusDescription,
        merchantAccountId,
        paypalCarrier
      },
      index
    ) => (
      <IndexTable.Row id={name} key={index}>
        <IndexTable.Cell>
          <p className="">
            <a
              style={{ color: "#315db0" }}
              href={`https://integration.palsync.com/orders/edit/${storeOrderId}`}
              target="_blank"
              rel="noreferrer"
            >
              {storeOrderId}
            </a>
          </p>
          <p className="table-data-secondary">{name}</p>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p className="">
            <a
              style={{ color: "#315db0" }}
              href={`https://www.paypal.com/activity/payment/${transactionId}`}
              target="_blank"
              rel="noreferrer"
            >
              {transactionId}
            </a>
          </p>
          <p className="table-data-secondary">{gateway}</p>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <p className="table-data">
            <a
              style={{ color: "#315db0" }}
              href={`https://www.trackingmore.com/en/${trackingNumber}`}
              target="_blank"
              rel="noreferrer"
            >
              {trackingNumber}
            </a>
          </p>
          <Text variant="bodyMd" as="span" alignment="" numeric>
            {(paypalCarrier !== "OTHER" && paypalCarrier !== carrier) ?
                (<p className="table-data-secondary">{carrier} -> {paypalCarrier}</p>)
                : (<p className="table-data-secondary">{carrier}</p>) }
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          {orderStatus === "SUCCESS" ? (
            statusDescription === "" ? (
              <Badge status="success" as="span" progress="complete">
                <p className="table-data">Success</p>
              </Badge>
            ) : (
              <Tooltip
                content={
                  <div className="d-flex flex-col gap-10">
                    <p className="warningDiscription">
                      {t(
                        WarningInfo(statusDescription)
                          ?.languageConstStatusMessage
                      )}
                    </p>
                  </div>
                }
              >
                <Badge status="success" as="span" progress="complete">
                  <p className="table-data">Success</p>
                </Badge>
              </Tooltip>
            )
          ) : orderStatus === "QUEUED" ? (
            <CustomBadge
              text="Queued"
              dot="#fff"
              textColor="#fff"
              color="#0000FF"
            />
          ) : orderStatus === "MISSING_INFO" &&
            (processingMethod === "MISSING_DEFAULT_MERCHANT_ACCOUNT_ID" || processingMethod === "MISSING_MERCHANT_ACCOUNT_ID") ? (
              statusMessage || statusDescription ? (
                <div className="d-flex gap-10 aligns-Items-center ">
                  <CustomBadge
                    text="Paypal not connected"
                    color="#FFD79D"
                    dot="#B98900"
                    textColor="black"
                  />
                  <Tooltip
                    content={
                      <div className="d-flex flex-col gap-10">
                        <p className="warningHeading">
                          {t(
                            WarningInfo(statusMessage)?.languageConstStatusMessage
                          )}
                        </p>
                        {/* <p className="warningDiscription">
                          {t(StatusDiscription(statusDescription))}
                        </p> */}
                         <p className="warningDiscription">
                          {statusDescription.includes(
                            "is not connected to Trackipal"
                          )
                            ? t("Merchant_ID_is_not_connected_to_Trackipal", {
                              merchantAccountId,
                              })
                            : t(
                                StatusDiscription(statusDescription)
                                  ?.languageConstStatusDiscription
                              )}
                        </p>
                      </div>
                    }
                  >
                    <Image src={Warning} className="mt-5" width={20} />
                  </Tooltip>
                </div>
              ) : (
                <CustomBadge
                  text="Paypal not connected"
                  color="#FFD79D"
                  dot="#B98900"
                  textColor="black"
                />
              )
          ) : (orderStatus === "MISSING_INFO" &&
              processingMethod === "MISSING_TRACKING_NUMBER") ||
            processingMethod === "MISSING_CARRIER" ||
            processingMethod === "MISSING_TRANSACTION_ID" ? (

            statusMessage || statusDescription ? (
              <div className="d-flex gap-10 aligns-Items-center ">
                <CustomBadge
                  text="Unfullfilled"
                  color="#FFD79D"
                  dot="#B98900"
                  textColor="black"
                />
                <Tooltip
                  content={
                    <div className="d-flex flex-col gap-10">
                      <p className="warningHeading">
                        {t(
                          WarningInfo(statusMessage)?.languageConstStatusMessage
                        )}
                      </p>
                      <p className="warningDiscription">
                        {t(
                          StatusDiscription(statusDescription)
                            ?.languageConstStatusDiscription
                        )}
                      </p>
                    </div>
                  }
                >
                  <Image src={Warning} className="mt-5" width={20} />
                </Tooltip>
              </div>
            ) : (
              <CustomBadge
                text="Unfullfilled"
                color="#FFD79D"
                dot="#B98900"
                textColor="black"
              />
            )
          ) : orderStatus === "PROCESSING" ? (
            <CustomBadge
            text="Processing"
            color="#2a52be"
            dot="#ffffff"
            textColor="#ffffff"
          />
          ) : orderStatus === "FAILED" ? (

            statusMessage || statusDescription ? (
              <div className="d-flex gap-10 aligns-Items-center">
                <Badge status="critical" as="span" progress="complete">
                  <p className="table-data">Failed</p>
                </Badge>
                <Tooltip
                  content={
                    <div className="d-flex flex-col gap-10">
                      <p className="warningHeading" style={{ color: "red" }}>
                        {t(WarningInfo(statusMessage)?.languageConstStatusMessage)}
                      </p>
                      <p className="warningDiscription">
                        {t(StatusDiscription(statusDescription)?.languageConstStatusDiscription)}
                      </p>
                    </div>
                  }
                >
                  <Image src={DangerWarning} className="mt-5" width={20} />
                </Tooltip>
              </div>
            ) : (
              <Badge status="critical" as="span" progress="complete">
                <p className="table-data">Failed</p>
              </Badge>
            )
          ) : orderStatus === "INVALID_GATEWAY"  ? (
            statusMessage || statusDescription  ?  (
              <div className="d-flex gap-10 aligns-Items-center">
                <CustomBadge
                  text="Invalid Gateway"
                  color="#FFD79D"
                  dot="#B98900"
                  textColor="black"/>
                <Tooltip
                  content={
                    <div className="d-flex flex-col gap-10">
                      <p className="warningHeading" style={{ color: "red" }}>
                        {t(
                          WarningInfo(statusMessage)?.languageConstStatusMessage
                        )}
                      </p>
                      <p className="warningDiscription">
                        {t(
                          StatusDiscription(statusDescription)
                            ?.languageConstStatusDiscription
                        )}
                      </p>
                    </div>
                         }
                >
                  <Image src={DangerWarning} className="mt-5" width={20} />
                </Tooltip>
              </div>
            ) : (
              <CustomBadge
                text="Invalid Gateway"
                color="#FFD79D"
                dot="#B98900"
                textColor="black"/>
            )
          ) : ("")}
        </IndexTable.Cell>

        <IndexTable.Cell>{formatDateTime(createdAt)}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const details = (
    <p key="details" className="table_heading">
      {t("Details")}
    </p>
  );
  const tranaction = (
    <p key="tranaction" className="table_heading">
      {t("Transaction")}
    </p>
  );
  const courier = (
    <p key="courier" className="table_heading">
      {t("Courier")}
    </p>
  );
  const status = (
    <p key="status" className="table_heading">
      {t("Status")}
    </p>
  );
  const DateTime = (
    <p key="DateTime" className="table_heading">
      {t("DateAndtime")}
    </p>
  );

  return (
    <>
      <div style={{ marginTop: "20px", padding: "" }}>
        <LegacyCard title="Orders" sectioned>
          <LegacyCard.Section>
            {OrdersTableData.isRequest ? (
              <TableSkeleton />
            ) : (
              <IndexTable
                resourceName={resourceName || 0}
                itemCount={customers.length}
                emptyState={emptyStateMarkup}
                headings={[
                  { id: "details", title: details },
                  { id: "tranaction", title: tranaction },
                  { id: "courier", title: courier },
                  { id: "status", title: status },
                  { id: "DateTime", title: DateTime },
                ]}
                selectable={false}
              >
                {rowMarkup}
              </IndexTable>
            )}
          </LegacyCard.Section>
          <LegacyCard.Section alignItems="right">
            {customers.length === 0 ? null : (
              <div className="text-align-right">
                <Button
                  primary
                  onClick={() => {
                    navigate("orders");
                  }}
                >
                  {t("viewAll")}
                </Button>
              </div>
            )}
          </LegacyCard.Section>
        </LegacyCard>
      </div>
    </>
  );
};

export default Table;
