import {Banner, Divider, Layout, LegacyCard, Page, Pagination,} from "@shopify/polaris";
import {React, useEffect, useState} from "react";
import DatePicker from "../../../components/datepicker/SingleDatePicker";
import Table from "./Table";
import {
  historicalOrdersQueue,
  historicalOrdersStatus,
  historicalOrdersTokenCount,
} from "../../../redux/store/historicalOrders/Action";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

const HistoricalOrder = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const { i18n, t } = useTranslation();
  const [userToken, setUserToken] = useState(1);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [date, setDate] = useState( moment(new Date()).format("YYYY-MM-DD"))
  const dispatch = useDispatch();
  const { queue, status, token } = useSelector(
    (store) => store.requiredHistoricalData
  );
  const historicalData = useSelector(
    (store) => store.requiredHistoricalData
  );


  useEffect(() => {
    const payload = { date: date, page: 0, shopId:id };
    dispatch(historicalOrdersStatus(payload));
    dispatch(historicalOrdersTokenCount(payload));
  }, []);

  useEffect(() => {
    if (
      queue &&
      status !== undefined &&
      status.storeBackInTimeTokens &&
      token !== undefined
    ) {
      setOrders(status.storeBackInTimeTokens);
      setUserToken(token.count);
      setPageCount(status.pageCount);
    }
    if(queue?.data?.message === "Actual error message" || 
    status?.message === "Actual error message" || 
    token?.message === "Actual error message"
    ) {
      navigate('/logout')
    }
  }, [queue, status, token]);

  useEffect(()=>{
    if(queue.isSuccess){
      const payload = {
        page:0,
        shopId:id,
        date:date
      }
      dispatch(historicalOrdersStatus(payload));
      dispatch(historicalOrdersTokenCount(payload));
    }

  },[queue]);  

  const handleOrdersQueue = (date,ordersCount) => {
    setDate(date);
    const payload = { date: date, page: 0, id:id, ordersCount:ordersCount,type:"USER"};
    dispatch(historicalOrdersQueue(payload));
  };
 
  const handlePreviousPagination = () => {
    if (currentPage === 0) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
      const payload = {
        name: null,
        country: null,
        chargesStatus: null,
        startDate: null,
        endDate: null,
        page: currentPage - 1
      };
      dispatch(historicalOrdersStatus(payload));
    }
  };

  const handleNextPagination = () => {
    if (currentPage === pageCount - 1) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
      const payload = {
        name: null,
        country: null,
        chargesStatus: null,
        startDate: null,
        endDate: null,
        page: currentPage + 1
      };
      dispatch(historicalOrdersStatus(payload));
    }
  };

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          {userToken === 0 ? (
            <Banner title={t("No_tokens_left")} status="warning">
              <p>{t("Your_account_will_be_credited_by_token_per_day")}</p>
            </Banner>
          ) : null}
        </Layout.Section>
        <Layout.Section>
          <LegacyCard title={t("Sync_orders_from_before_you_installed_Trackipal")}>
            <div style={{ marginTop: "30px", marginRight: "20px" }}>
              <Layout.Section>
                <Divider borderStyle="base" />
              </Layout.Section>
            </div>
            <DatePicker
              userTotalToken={userToken}
              handleOrdersQueue={handleOrdersQueue}
            />
            <div>
                <Table loading={historicalData.isRequest} orders={orders} />
            </div>
            <Divider />
            <div
              className="paginationContainer"
              style={{ display: pageCount > 1 ? "flex" : "none" }}
            >
              <Pagination
                label={currentPage + 1}
                hasPrevious
                onPrevious={() => {
                  handlePreviousPagination();
                }}
                hasNext
                onNext={() => {
                  handleNextPagination();
                }}
              />
            </div>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default HistoricalOrder;
