import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import Dashboard from "./pages/dashboard";
import Store from "./pages/stores";
import Global from "./pages/global";
import Carrier from "./pages/carriers";
import Report from "./pages/reports";
import Gdpr from "./pages/gdpr";
import { Route, Routes, useNavigate } from "react-router-dom";
import Setting from "./pages/setting";
import UserCustomDashboard from "./pages/userCustomDashboard";
import { Frame, Navigation, TopBar, Avatar, Popover, Icon } from "@shopify/polaris";
import "./pages/global.css";
import ProtectedRoute from "./context/auth/protect-route";
import UserDashboard from "./pages/userCustomDashboard/dashboard";
import CourierMapping from "./pages/userCustomDashboard/settings/CourierMapping";
import Orders from "./pages/userCustomDashboard/orders";
import TroubleShoot from "./pages/troubleshooting";
import HistoricalOrder from "./pages/userCustomDashboard/HistoricalOrders";
import DigitalProduct from "./pages/userCustomDashboard/settings/DigitalProduct";
import Subscription from "./pages/userCustomDashboard/settings/Subscription";
import PaypalSetting from "./pages/userCustomDashboard/settings/PaypalSetting"; 

import {
  AnalyticsMinor,
  CircleRightMajor,
  ConversationMinor,
  HomeMajor,
  NotificationMajor,
  SettingsMajor,
  ShipmentMajor,
  StoreMajor,
  AnalyticsMajor
} from "@shopify/polaris-icons";
import { HeaderLogo } from "./assets";

const Main = (props) => {
  useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname === "/") {
      props.setState("/");
    } else if (window.location.pathname === "/login") {
      props.setState("/login");
    } else {
      props.setState("/dashboard");
    }
  }, [props.state]);
  const navigate = useNavigate();
  const skipToContentRef = useRef < HTMLAnchorElement > null;
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);

  const { user = {}, logout } = useAuth0();
  const [name, setName] = useState();

  useEffect(() => {
    if(user){
      setName(user?.nickname);
    }
  },[user])

  const toggleUserMenuActive = useCallback(() => {
    setUserMenuActive(!userMenuActive);
  }, [userMenuActive]);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    logout({
      returnTo: window.location.origin,
    })
  }, []);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const handleNavigation = (tabname, tabs) => {
    navigate(tabname);
  };

  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const activator = (
    <span
    className="d-flex gap-10 cursor_pointer aligns-Items-center mr- justify-content-center"

      onClick={toggleUserMenuActive}
    >
      <p className="capitalize">{name}</p>
      <Avatar name="Shop One" shape="square" />
    </span>
  );

  const userMenuMarkup = (
    <Popover
      active={userMenuActive}
      activator={activator}
      autofocusTarget="first-node"
      onClose={toggleUserMenuActive}
    >
      <div className="d-flex p-15 gap-10 cursor_pointer" style={{ background:"#fff"}}
        onClick={() => {
          handleLogout();
        }}
      >
        <Icon source={CircleRightMajor}   color="base"/>
        <p>Logout</p>
      </div>
    </Popover>
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location={"/dashboard"}>
      <Navigation.Section
        items={[
          {
            label: "Dashboard",
            icon: HomeMajor,
            onClick: () => {
              handleNavigation("/dashboard", 0);
            },
            selected:
              window.location.pathname === "/dashboard" ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem("id")}/courier-mapping` ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem("id")}` ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem("id")}/orders` ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem(
                  "id"
                )}/historical-orders` ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem("id")}/trouble-shooting` ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem("id")}/subscription` ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem("id")}/digital-product` ||
              window.location.pathname ===
                `/store/${sessionStorage.getItem("id")}/paypalsetting`
                ? true
                : false,
          },
          {
            label: "Stores",
            icon: StoreMajor,
            onClick: () => {
              handleNavigation("stores", 1);
            },
            selected: window.location.pathname === "/stores" ? true : false,
          },

          {
            label: "Gdpr",
            icon: AnalyticsMajor,
            onClick: () => {
              handleNavigation("gdpr", 2);
            },
            selected: window.location.pathname === "/gdpr" ? true : false,
          },
        ]}
        action={{
          icon: ConversationMinor,
          accessibilityLabel: "Contact support",
          onClick: toggleModalActive,
        }}
      />
    </Navigation>
  );

  const logo = {
    width: 50,
    topBarSource: HeaderLogo,
  };

  return (
    <>
      <Frame
        logo={
          props.state === "/login" ? null : props.state !== "/" ? logo : null
        }
        topBar={
          props.state === "/login"
            ? null
            : props.state !== "/"
            ? topBarMarkup
            : null
        }
        navigation={
          props.state === "/login"
            ? null
            : props.state !== "/"
            ? navigationMarkup
            : null
        }
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
      >
        <Routes>
          <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
          <Route path="/stores" element={<ProtectedRoute component={Store} />} />
          <Route path="/global" element={<ProtectedRoute component={Global} />} />
          <Route path="/carrier" element={<ProtectedRoute component={Carrier} />} />
          <Route path="/report" element={<ProtectedRoute component={Report} />} />
          <Route path="/gdpr" element={<ProtectedRoute component={Gdpr} />} />
          <Route path="/setting" element={<ProtectedRoute component={Setting} />} />
          <Route path="/trobleshoot" element={<ProtectedRoute component={TroubleShoot} />} />
          <Route path="/store/:id" element={<ProtectedRoute component={UserCustomDashboard} />}>
            <Route path="" element={<UserDashboard />} />
            <Route path="orders" element={<Orders />} />
            <Route path="courier-mapping" element={<CourierMapping />} />
            <Route path="historical-orders" element={<HistoricalOrder />} />
            <Route path="trouble-shooting" element={<TroubleShoot />} />
            <Route path="digital-product" element={<DigitalProduct />} />
            <Route path="paypalsetting" element={<PaypalSetting />} />
          </Route>
        </Routes>
      </Frame>
    </>
  );
};

export default Main;
