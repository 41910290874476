import {Button, Icon, Image, LegacyCard, Tabs} from "@shopify/polaris";
import React, {useEffect, useState} from "react";
import {MobileBackArrowMajor} from "@shopify/polaris-icons";
import {Setting, SettingWhite} from "../../assets";
import {Outlet, useNavigate} from "react-router-dom";
import Dropdown from '../../components/languagePicker/Dropdown';
import {useTranslation} from "react-i18next";

function UserCustomDashboard() {
  const [selected, setSelected] = useState(1);
  const [settingActive, setSettingActive] = useState(false);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();


  
  const tabs = [
    {
      id: 0,
      content: <Icon source={MobileBackArrowMajor} />,
    },
    {
      id: 1,
      content: t("Dashboard"),
      url: "",
    },
    {
      id: 2,
      content: t("Orders"),
      url: "orders",
    },
    {
      id: 3,
      content:  t("HistoricalOrders"),
      url: "historical-orders",
    },
    {
      id: 4,
      content:t("CourierMapping"),
      url: "courier-mapping",
    },
    {
      id: 5,
      content: t("TroubleShooting"),
      url: "trouble-shooting",
    },
  ];


  const handleTabChange = (selectedTabIndex) => {
    if (selectedTabIndex === 0) {
      return navigate("/dashboard");
    }
    const currentTab = tabs.find((tab) => tab.id === selectedTabIndex);
    navigate(currentTab.url);
    setSelected(selectedTabIndex);
    sessionStorage.setItem('selectedTabIndex', selectedTabIndex)
  };

  useEffect(() => {
    if (
      selected === 0 ||
      selected === 1 ||
      selected === 2 ||
      selected === 3 ||
      selected === 5
    ) {
      setSettingActive(false);
    } else setSettingActive(true);
  
    if(sessionStorage.getItem("selectedTabIndex")){
      setSelected(Number(sessionStorage.getItem("selectedTabIndex")))
    }
    else setSelected(1);


  }, [selected]);

  return (
    <>
      <LegacyCard>
        <div
          className="d-flex aligns-Items-center justify-content-between w-100 bg-white tab_bar_wrap"
        >
          <Tabs
            tabs={tabs}
            selected={selected}
            onSelect={handleTabChange}
          ></Tabs>
          <div className="topbarSetting">
            <Dropdown/>
            <Button
              pressed={settingActive}
              onClick={() => {
                navigate('paypalsetting')
                setSettingActive(!settingActive);
              }}
            >
              <div className="d-flex gap-10">
                <Image
                  source={!settingActive ? Setting : SettingWhite}
                  alt=""
                  width={15}
                />
                <p style={{ color: settingActive ? "white" : "#202223" }}>
                  {t("Setting")}
                </p>
              </div>
            </Button>
          </div>
        </div>
      </LegacyCard>
       <Outlet/>
    </>
  );
}

export default UserCustomDashboard;
