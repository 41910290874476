import {ROUTES_NAME} from "../../config/constants";
import {ALL_COUNTRIES_ERROR, ALL_COUNTRIES_REQUEST, ALL_COUNTRIES_SUCCESS} from "./ActionType";
import {shopID} from '../../utils'
import { ACCESS_TOKEN } from "../../constants";

export const allCountriesApi = () => {
  return (dispatch) => {
    dispatch(allCountriesRequest());
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${ROUTES_NAME.all_countries()}`;

    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json',
        'Origin': `${process.env.REACT_APP_URL}`
      },
    })
      .then((res) => res.json())
      .then((allCountriesDetails) => {
        dispatch(allCountriesSuccess(allCountriesDetails));
      })
      .catch((data) => {
        console.log(data,"error")
        dispatch(allCountriesError());
      });
  };
};

function allCountriesRequest(allCountriesData) {
  return {
    type: ALL_COUNTRIES_REQUEST,
    payload: allCountriesData,
  };
}

function allCountriesSuccess(allCountriesData) {
    return {
        type: ALL_COUNTRIES_SUCCESS,
        payload: allCountriesData,
    };
}

function allCountriesError(allCountriesData) {
    return {
        type: ALL_COUNTRIES_ERROR,
        payload: allCountriesData,
    };
}
